import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
  useId,
  useState,
} from 'react';

import { FiClipboard, FiEye, FiEyeOff } from 'react-icons/fi';
import { IconType } from 'react-icons';

import { useCopyToClipboard } from '~/shared/hooks/useCopyToClipboard';

import { IMarginProps } from '~/shared/interfaces/IMarginProps';
import { TInputSize } from './types';

import {
  Container,
  InputWrapper,
  PasswordButton,
  IconWrapper,
  EndContentWrapper,
  CopyButton,
} from './styles';

import { Label } from '../Label';
import { ErrorMessage } from '../ErrorMessage';
import { Tooltip } from '../../Tooltip';

export interface IInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>,
    IMarginProps {
  label?: string;
  icon?: IconType;
  endContent?: ReactNode;
  error?: string;
  hasError?: boolean;
  size?: TInputSize;
  containerStyle?: CSSProperties;
  enableCopyButton?: boolean;
  disabledHint?: ReactNode;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (
  {
    label,
    icon: Icon,
    endContent,
    type,
    error,
    hasError,
    m,
    ml,
    mr,
    mt,
    mh,
    mb,
    mv,
    size = 'md',
    containerStyle = {},
    id: propsId,
    disabled,
    enableCopyButton = false,
    disabledHint,
    ...props
  },
  ref
) => {
  const id = useId();

  const { copy } = useCopyToClipboard();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleIsPasswordVisible = (): void => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleCopyValue = (): void => {
    const successMessage = `Boa, ${label || 'valor'} copiado com sucesso!`;
    const errorMessage = `Ops, não foi possível copiar o ${label || 'valor'}!`;

    copy(String(props.value), {
      errorMessage,
      successMessage,
    });
  };

  return (
    <Container
      m={m}
      mv={mv}
      mh={mh}
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
      htmlFor={propsId || id}
    >
      {!!label && <Label mb="xxs">{label}</Label>}

      <Tooltip label={disabledHint}>
        <InputWrapper
          hasIcon={!!Icon}
          isTypePassword={type === 'password'}
          hasError={hasError || !!error}
          size={size}
          style={containerStyle}
          isDisabled={disabled}
        >
          {!!Icon && (
            <IconWrapper inputSize={size}>
              <Icon />
            </IconWrapper>
          )}

          <input
            type={
              type === 'password'
                ? isPasswordVisible
                  ? 'text'
                  : 'password'
                : type
            }
            {...props}
            disabled={disabled}
            id={propsId || id}
            ref={ref}
          />

          {enableCopyButton && (
            <Tooltip label={label ? `Copiar ${label}` : 'Copiar'}>
              <CopyButton
                type="button"
                onClick={handleCopyValue}
                inputSize={size}
                {...((!!endContent || type === 'password') && {
                  style: { width: 20, marginLeft: 12 },
                })}
              >
                <FiClipboard />
              </CopyButton>
            </Tooltip>
          )}

          {endContent ? (
            <EndContentWrapper inputSize={size}>{endContent}</EndContentWrapper>
          ) : (
            type === 'password' && (
              <Tooltip
                label={
                  isPasswordVisible
                    ? `Esconder ${label || 'senha'}`
                    : `Mostrar ${label || 'senha'}`
                }
              >
                <PasswordButton
                  type="button"
                  onClick={toggleIsPasswordVisible}
                  inputSize={size}
                >
                  {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                </PasswordButton>
              </Tooltip>
            )
          )}
        </InputWrapper>
      </Tooltip>

      <ErrorMessage mt="xxs">{error}</ErrorMessage>
    </Container>
  );
};

export const Input = forwardRef(BaseInput);
